import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    deleteSubCategory: {},
    isLoading: false,
    error: null
}

const deleteSubCategorySlice = createSlice({
    name: 'deleteSubCategory',
    initialState,
    reducers:{
        deleteSubCategoryPending: (state) => {
            state.isLoading = true;
        },
        deleteSubCategorySuccess: (state, {payload}) => {
            state.isLoading = false;
            state.deleteSubCategory = payload?.delete;
            state.error = null;
        },
        deleteSubCategoryFail: (state, {payload}) => {
            state.isLoading = false;
            state.error = payload;
        }
    }
})

export const {deleteSubCategoryPending, deleteSubCategorySuccess, deleteSubCategoryFail} = deleteSubCategorySlice.actions

export default deleteSubCategorySlice.reducer