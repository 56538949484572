import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    distributorAuth: {},
    loading: false,
    error: null
}

const distributorAuthSlice = createSlice({
    name: 'distributorAuth',
    initialState,
    reducers:{
        authPending: (state) => {
            state.loading = true;
        },
        authSuccess: (state, {payload}) => {
            state.loading = false;
            state.distributorAuth = payload?.data;
            state.error = null;
        },
        authFail: (state, {payload}) => {
            state.loading = false;
            state.error = Array.isArray(payload?.errors) ? payload : payload?.response?.data?.message;;
        }
    }
})

export const {authPending, authSuccess, authFail} = distributorAuthSlice.actions

export default distributorAuthSlice.reducer