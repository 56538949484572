import { configureStore } from '@reduxjs/toolkit';
import headerSlice from '../features/common/headerSlice';
import modalSlice from '../features/common/modalSlice';
import rightDrawerSlice from '../features/common/rightDrawerSlice';
import leadsSlice from '../features/leads/leadSlice';
import getProductsReducer from "../features/products/productSlices/productSlice";
import LoginReducer  from "../features/user/userSlices/LoginSlice"
import getUsersReducer from "../features/settings/team/userSlices/userSlice"
import getCategoriesReducer from "../features/categories/categorySlices/categorySlice"
import orderReducer from "../features/transactions/slices/orderSlice"
import profileReducer from '../features/settings/profilesettings/slices/profileSlice';
import addProductReducer from '../features/products/productSlices/addProductSlice'
import LogoutReducer from '../features/settings/team/userSlices/LogoutSlice'
import AddUserReducer from '../features/settings/team/userSlices/AddUserSlice'
import customerReducer from '../features/settings/customers/customerSlices/customerSlice';
import distributorReducer from '../features/settings/distributors/distributorSlices/distributorSlice';
import addCategoryReducer from '../features/categories/categorySlices/addCategorySlice';
import editCategoryReducer from '../features/categories/categorySlices/editCategorySlice';
import deleteCategoryReducer from '../features/categories/categorySlices/deleteCategorySlice';
import deleteProductReducer from '../features/products/productSlices/deleteProductSlice';
import updateProductReducer from '../features/products/productSlices/updateProductSlice';
import addSubCategoryReducer from '../features/subcategories/subcategorySlices/addSubCategorySlice';
import editSubCategoryReducer from '../features/subcategories/subcategorySlices/editSubCategorySlice';
import deleteSubCategoryReducer from '../features/subcategories/subcategorySlices/deleteSubCategorySlice';
import dashboardReducer from '../features/dashboard/dashboardSlices/dashboardSlice';
import orderStatusReducer from '../features/transactions/slices/orderStatusSlice';
import SignInReducer from '../features/settings/distributors/distributorSlices/SignInSlice';
import authReducer from '../features/settings/distributors/distributorSlices/authSlice';
import addStockReducer from '../features/stockmanagement/stockSlices/addStockSlice';
import stockReducer from '../features/stockmanagement/stockSlices/stockSlice';
import deleteStockReducer from '../features/stockmanagement/stockSlices/deleteStockSlice';
import updateStockReducer from '../features/stockmanagement/stockSlices/updateStockSlice';
import wholesalerReducer from '../features/settings/wholesalers/wholesalerSlices/wholesalerSlice';

const combinedReducer = {
  header: headerSlice,
  rightDrawer: rightDrawerSlice,
  modal: modalSlice,
  lead: leadsSlice,
  getProductsList: getProductsReducer,
  getUserList: getUsersReducer,
  categoryList : getCategoriesReducer,
  ordersList: orderReducer,
  authProfile: profileReducer,
  authUser : LoginReducer,
  addproducts : addProductReducer,
  addcategories: addCategoryReducer,
  logout : LogoutReducer,
  adduser : AddUserReducer,
  customerlist: customerReducer,
  distributorlist: distributorReducer,
  editcategory: editCategoryReducer,
  deletesCategory: deleteCategoryReducer,
  updatingProduct: updateProductReducer,
  deletingProduct: deleteProductReducer,
  addsubcategory: addSubCategoryReducer,
  editsubcategory: editSubCategoryReducer,
  deletesubcategory: deleteSubCategoryReducer,
  dash: dashboardReducer,
  orderUpdate: orderStatusReducer,
  signedin: SignInReducer,
  auth: authReducer,
  addstock : addStockReducer,
  stocklist: stockReducer,
  deletestock: deleteStockReducer,
  updatestock: updateStockReducer,
  wholesalers:wholesalerReducer

};

export default configureStore({
  reducer: combinedReducer,
});
