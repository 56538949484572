import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    stockupdate: {},
    isLoading: false,
   error : null
}

const updateStockSlice = createSlice({
    name: 'stockupdate',
    initialState,
    reducers:{
        updateStockPending: (state) => {
            state.isLoadingStocks = true;
        },
        updateStockSuccess: (state, {payload}) => {
            state.isLoading = false;
            state.stockupdate = payload?.updated;
            state.error = null;
        },
        updateStockFail: (state, {payload}) => {
            state.isLoading = false;
            state.error = payload;
        }
    }
})

export const {updateStockPending, updateStockSuccess, updateStockFail} = updateStockSlice.actions

export default updateStockSlice.reducer