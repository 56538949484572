import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    getUsersList: {},
    isLoadingUsers: false,
    errorGetUsers: ''
}

const getUsersSlice = createSlice({
    name: 'getUsersList',
    initialState,
    reducers:{
        getUsersPending: (state) => {
            state.isLoadingUsers = true;
        },
        getUsersSuccess: (state, {payload}) => {
            state.isLoadingUsers = false;
            state.getUsersList = payload;
            state.errorGetUsers = '';
        },
        getUsersFail: (state, {payload}) => {
            state.isLoadingUsers = false;
            state.errorGetUsers = payload;
        }
    }
})

export const {getUsersPending, getUsersSuccess, getUsersFail} = getUsersSlice.actions

export default getUsersSlice.reducer