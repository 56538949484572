import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    list: {},
    isLoading: false,
    error: null
}

const getWholesalerSlice = createSlice({
    name: 'list',
    initialState,
    reducers: {
        wholesalerPending: (state) => {
            state.isLoading = true;
        },
        wholesalerSuccess: (state, { payload }) => {
            state.isLoading = false;
            state.list = payload?.data;
            state.error = null;
        },
        wholesalerFail: (state, { payload }) => {
            state.isLoading = false;
            state.error = payload;
        }
    }
})

export const { wholesalerPending, wholesalerSuccess, wholesalerFail } = getWholesalerSlice.actions

export default getWholesalerSlice.reducer