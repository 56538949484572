import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    list: {},
    isLoading: false,
 error : null
}

const getDistributorSlice = createSlice({
    name: 'list',
    initialState,
    reducers:{
        distributorPending: (state) => {
            state.isLoading = true;
        },
        distributorSuccess: (state, {payload}) => {
            state.isLoading = false;
            state.list = payload?.data;
            state.error = null;
        },
        distributorFail: (state, {payload}) => {
            state.isLoading = false;
            state.error = Array.isArray(payload?.errors) ? payload : payload?.response?.data?.message;;
        }
    }
})

export const {distributorPending, distributorSuccess, distributorFail} = getDistributorSlice.actions

export default getDistributorSlice.reducer