import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    addStock: {},
    isLoading: false,
     error : null
}

const addStockSlice = createSlice({
    name: 'addStock',
    initialState,
    reducers:{
        addStockPending: (state) => {
            state.isLoadingStocks = true;
        },
        addStockSuccess: (state, {payload}) => {
            state.isLoading = false;
            state.addStock = payload;
            state.error = null;
        },
        addStockFail: (state, {payload}) => {
            state.isLoading = false;
            state.error = payload;
        }
    }
})

export const {addStockPending, addStockSuccess, addStockFail} = addStockSlice.actions

export default addStockSlice.reducer