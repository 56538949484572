import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    deleteStock: {},
    isLoading: false,
   error : null
}

const deleteStockSlice = createSlice({
    name: 'deleteStock',
    initialState,
    reducers:{
        deleteStockPending: (state) => {
            state.isLoadingStocks = true;
        },
        deleteStockSuccess: (state, {payload}) => {
            state.isLoading = false;
            state.deleteStock = payload;
            state.error = null;
        },
        deleteStockFail: (state, {payload}) => {
            state.isLoading = false;
            state.error = payload;
        }
    }
})

export const {deleteStockPending, deleteStockSuccess, deleteStockFail} = deleteStockSlice.actions

export default deleteStockSlice.reducer