import { createSlice } from "@reduxjs/toolkit";
import { error } from "daisyui/src/colors";

const initialState = {
    isLoading:false,
    profile: {},
    error:null
}
const profileSlice = createSlice({
    name:'profile',
    initialState,
    reducers:{
        profilePending: (state)=>{
            state.isLoading = true;
        },
        profileSuccess: (state, {payload}) =>{
            sessionStorage.setItem('userProfile', JSON.stringify(payload?.profile))
            state.isLoading = false;
            state.profile = payload?.profile;
            state.error = null
        },
        profileFail: (state, {payload}) =>{
            state.isLoading = false;
            state.error = payload;
        }
    }
})
export const {profilePending, profileSuccess, profileFail} = profileSlice.actions;
export default profileSlice.reducer;