import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    stock: {},
    loading: false,
    error: ''
}

const getStockSlice = createSlice({
    name: 'stock',
    initialState,
    reducers:{
        getStockPending: (state) => {
            state.loading = true;
        },
        getStockSuccess: (state, {payload}) => {
            state.loading = false;
            state.stock = payload?.stock;
            state.error = '';
        },
        getStockFail: (state, {payload}) => {
            state.loading = false;
            state.error = payload;
        }
    }
})

export const {getStockPending, getStockSuccess, getStockFail} = getStockSlice.actions

export default getStockSlice.reducer