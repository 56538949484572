import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    signin: {},
    loading: false,
    error: null
}

const signinSlice = createSlice({
    name: 'signin',
    initialState,
    reducers:{
        signinPending: (state) => {
            state.loading = true;
        },
        signinSuccess: (state, {payload}) => {
            state.loading = false;
            state.signin = payload?.data;
            state.error = null;
        },
        signinFail: (state, {payload}) => {
            state.loading = false;
            state.error = Array.isArray(payload?.response?.data?.errors) ? payload?.response?.data : payload?.response?.data?.message;;
        }
    }
})

export const {signinPending, signinSuccess, signinFail} = signinSlice.actions

export default signinSlice.reducer